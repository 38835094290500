import React from 'react';

import Footer from '../../templates/throttle/footer/footer';
import Hero from '../../templates/throttle/hero/hero';
import SEO from '../seo';

import SuccessHeroFullThrottleStyles from '../../styles/Components/FullThrottleThankYou/SuccessHeroFullThrottleStyles';

const SuccessHeroFullThrottle = ({ isSanJose, isThankYou }) => (
  <SuccessHeroFullThrottleStyles>
    <SEO />
    <Hero isStatic child isSanJose={isSanJose} isThankYou={isThankYou} />
    <Footer isStatic child />
  </SuccessHeroFullThrottleStyles>
);

export default SuccessHeroFullThrottle;
