import React from 'react';
import SEO from '../seo';
import SuccessHeroFullThrottle from './SuccessHeroFullThrottle';

const ThankYouPage = ({ location }) => (
  <>
    <SEO />
    <SuccessHeroFullThrottle
      isSanJose={location?.state?.isSanJose}
      isThankYou={location?.state?.isThankYou}
    />
  </>
);

export default ThankYouPage;
